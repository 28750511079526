










































import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import LayoutSection from '@/layouts/LayoutSection.vue';
import Vue, { VueConstructor } from 'vue';
import { invoiceGridActionList } from '@/utils/gridActions/invoiceGridActionList';
import { invoiceRouteEdit, tabDeliveryInvoice, tabInvoice } from '@/views/sales/invoice/routes';
import { MInvoice } from '@/models/MInvoice';
import { mixinModal } from '@/components/mixins/modal';
import { mixinPDF } from '@/components/mixins/pdf';
import { sortAlphaNum } from '@/utils/filters/sortAlphaNumeric';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'TabInvoice',

  components: {
    BaseGridTable,
    BaseSelect,
    LayoutSection,
  },

  mixins: [
    mixinPDF,
    mixinModal,
  ],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    deliveryInvoice: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      gridActionList: invoiceGridActionList,
      invoiceYear: String(new Date().getFullYear()),
      yearList: [],
    };
  },

  computed: {
    displayedInvoiceYear(): Object {
      return {
        year: String(this.invoiceYear),
      };
    },

    gridColumnDefinition(): Array<any> {
      return [
        {
          headerName: `${this.$tc('invoice.model', 1)} ${this.$t('invoice.property.number')}`,
          field: 'invoiceNumber',
          filter: true,
          comparator: (a, b) => {
            return sortAlphaNum(a, b);
          },
        },
        {
          headerName: this.$tc('customer.model', 1),
          field: 'customerName',
          filter: true,
        },
        {
          headerName: this.$t('invoice.property.reversal'),
          field: 'reversal',
          filter: true,
        },
        {
          headerName: this.$t('invoice.property.canceled'),
          field: 'canceled',
          filter: true,
        },
        {
          headerName: this.$t('invoice.property.paid'),
          field: 'datePaid',
          filter: true,
        },
      ];
    },

    invoiceList(): Array<MInvoice> {
      if (this.deliveryInvoice) {
        return MInvoice
          .query()
          .where('DeliveryInvoice', true)
          .where(invoice => {
            return invoice.Date.substr(0, 4) === this.invoiceYear;
          }).get();
      }

      return MInvoice
        .query()
        .where('DeliveryInvoice', false)
        .where(invoice => {
          return invoice.Date.substr(0, 4) === this.invoiceYear;
        }).get();
    },

    gridRowData(): Array<any> {
      return this.invoiceList.map(
        invoice => invoice.asListData,
      ).sort((a, b) => {
        return a.invoiceNumber - b.invoiceNumber;
      });
    },
  },

  methods: {
    async createReversal(Id: string): Promise<any> {
      const ongoingNumber = await MInvoice._getOngoingNumber();

      MInvoice.update({
        where: Id,
        data: {
          CancelInvoiceDate: new Date().toISOString(),
          CancelInvoiceNumber: ongoingNumber,
        },
      });

      let invoice = MInvoice.query().withAllRecursive().find(Id);

      await MInvoice._update(invoice);

      let reversalId = '';

      if (invoice.DeliveryInvoice) {
        reversalId = await MInvoice._cancelDeliveryInvoice(Id);
      } else {
        reversalId = await MInvoice._cancel(Id);
      }

      invoice = MInvoice.query().withAllRecursive().find(reversalId);

      if (invoice.DeliveryInvoice) {
        this.$refs['hiddenPdf'].src = await this.generateDeliveryInvoicePdf(invoice, 'reversal');
      } else {
        this.$refs['hiddenPdf'].src = await this.generatePdf(invoice, 'reversal');
      }
    },

    edit(Id: string): void {
      this.$router.push({
        name: invoiceRouteEdit(this.$i18n.locale).name,

        params: {
          invoiceID: Id,
          routeBack: (this.deliveryInvoice) ? tabDeliveryInvoice(this.$i18n.locale).name : tabInvoice(this.$i18n.locale).name,
        },
      });
    },

    async loadInvoiceYear(year: any): Promise<any> {
      this.invoiceYear = String(year.year);
    },

    async openBon(Id: string): Promise<any> {
      let type;
      const invoice = MInvoice.query().withAllRecursive().find(Id);

      if (invoice.InvoiceNumber === invoice.CancelInvoiceNumber) {
        type = 'reversal';
      }

      this.$refs['hiddenPdf'].src = await this.generateBon(invoice, type);
    },

    async openPdf(Id: string): Promise<any> {
      let type;
      const invoice = MInvoice
        .query()
        .withAllRecursive()
        .find(Id);

      if (invoice.InvoiceNumber === invoice.CancelInvoiceNumber) {
        type = 'reversal';
      }

      if (invoice.DeliveryInvoice) {
        this.$refs['hiddenPdf'].src = await this.generateDeliveryInvoicePdf(invoice, type);
      } else {
        this.$refs['hiddenPdf'].src = await this.generatePdf(invoice, type);
      }
    },

    async openReversal(Id: string): Promise<any> {
      const invoice = MInvoice
        .query()
        .withAllRecursive()
        .find(Id);

      const reversal = MInvoice
        .query()
        .withAllRecursive()
        .where('InvoiceNumber', invoice.CancelInvoiceNumber)
        .first();

      if (reversal.DeliveryInvoice) {
        this.$refs['hiddenPdf'].src = await this.generateDeliveryInvoicePdf(reversal, 'reversal');
      } else {
        this.$refs['hiddenPdf'].src = await this.generatePdf(reversal, 'reversal');
      }
    },
  },

  async beforeMount(): Promise<any> {
    for (let i = 0; i < 8; i++) {
      this.yearList.push(
        {
          year: String((Number(new Date().getFullYear()) - i)),
        },
      );
    }
  },
});
