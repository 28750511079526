


























































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import { i18n } from '@/locales/i18n';
import { MCustomer } from '@/models/MCustomer';
import { MInvoice } from '@/models/MInvoice';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinPDF } from '@/components/mixins/pdf';
import { MMail } from '@/models/MMail';
import { SELECTIONSTATE } from '@/utils/states';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'SendMail',

  components: {
    BaseButtonText,
    BaseCheckbox,
    BaseInputField,
    BaseRow,
    BaseSideLabel,
    LayoutModalEdit,
    LayoutHeader,
  },

  mixins: [
    mixinModal,
    mixinModes,
    mixinPDF,
  ],

  props: {
    invoiceID: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      recipient: '',
      recipientMail: '',
      differentRecipient: SELECTIONSTATE.UNCHECKED,
      SELECTIONSTATE,
    };
  },

  computed: {
    customer(): MCustomer {
      if (this.invoice && this.invoice?.CustomerID) {
        return MCustomer.find(this.invoice.CustoemrID);
      }

      return null;
    },

    invoice(): MInvoice {
      return MInvoice
        .query()
        .withAllRecursive()
        .find(this.invoiceID);
    },

    isDisabled(): boolean {
      if (this.invoice && utIsEmpty(this.invoice.CustomerID)) {
        return true;
      }

      return false;
    },
  },

  methods: {
    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async sendInvoice(): Promise<void> {
      this.loading = true;

      let type = '';
      let fileName = '';
      let recipient, invoiceNumber;

      if (this.invoice.InvoiceNumber === this.invoice.CancelInvoiceNumber) {
        type = 'reversal';
      }

      let pdf;

      if (this.invoice.DeliveryInvoice) {
        pdf = await this.generateDeliveryInvoicePdf(this.invoice, type, false, true);
      } else {
        pdf = await this.generatePdf(this.invoice, type, false, true);
      }

      pdf = pdf.replace('data:application/pdf;base64,', '');

      if (type === 'reversal') {
        invoiceNumber = this.invoice.CancelInvoiceNumber;

        fileName = `${this.$tc('invoice.model', 1)}-${this.invoice.CancelInvoiceNumber}`;
      } else {
        invoiceNumber = this.invoice.InvoiceNumber;

        fileName = `${this.$tc('invoice.model', 1)}-${this.invoice.InvoiceNumber}`;
      }

      if (this.customer && !utIsEmpty(this.customer.Email)) {
        recipient = {
          address: this.customer.Email,
          name: this.customer.name,
        };
      }

      if (this.differentRecipient === SELECTIONSTATE.CHECKED) {
        recipient = {
          address: this.recipientMail,
          name: this.recipient,
        };
      }

      const mail = {
        attachment: pdf,
        customer: recipient,
        fileName: `${fileName}.pdf`,
        message: this.$t('mailing.message.invoice', {
          customer: recipient?.name,
          invoiceNumber: invoiceNumber,
        }),
        subject: fileName,
      };

      await MMail._sendMail(mail);

      this.loading = false;

      this.closeModal();
    },

    showMailForm(): void {
      this.differentRecipient = true;
    },
  },

  beforeMount(): void {
    if (this.invoice && utIsEmpty(this.invoice.CustomerID)) {
      this.differentRecipient = SELECTIONSTATE.CHECKED;
    }
  },
});
