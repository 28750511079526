
















import BaseTabs from '@/components/base/BaseTabs.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { invoiceRouteShow } from '@/views/sales/invoice/routes';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { mixinTabList } from '@/components/mixins/tabList';
import { modes } from '@/utils/TModes';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'InvoiceShow',

  components: {
    BaseTabs,
    LayoutPage,
  },

  mixins: [
    mixinTabList,
  ],

  props: {
    mode: {
      type: String,
      default: modes.EDIT,
    },
  },

  computed: {
    routeArray(): Array<IRouteConfig> {
      return [
        invoiceRouteShow(this.$i18n.locale),
      ];
    },
  },
});
