import { ACTION, actionMap } from './actionMap';
import { IBaseGridActionListItem } from '@/components/base/grid/typings/interface/IBaseGridActionListItem';

const invoiceGridActionList: Array<IBaseGridActionListItem> = [
  {
    ...actionMap.get(ACTION.CONTEXT_MENU),
    childActionList: [
      actionMap.get(ACTION.PDF),
      actionMap.get(ACTION.BON),
      actionMap.get(ACTION.CANCEL),
      actionMap.get(ACTION.REVERSAL),
    ],
  },
];

export { invoiceGridActionList };
