















































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import { invoiceSendMail } from '@/views/sales/invoice/routes';
import { MInvoice } from '@/models/MInvoice';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinPDF } from '@/components/mixins/pdf';
import { mixinSync } from '@/components/mixins/sync';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'InvoiceEdit',

  components: {
    BaseButtonText,
    BaseDatePicker,
    BaseInputField,
    BaseRow,
    BaseSideLabel,
    LayoutModalEdit,
    LayoutHeader,
  },

  mixins: [
    mixinModal,
    mixinModes,
    mixinPDF,
    mixinSync,
  ],

  props: {
    invoiceID: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      recipient: '',
      recipientMail: '',
      viaMail: false,
    };
  },

  computed: {
    hasReversal(): boolean {
      if (this.invoice) {
        return !utIsEmpty(this.invoice.asListData.canceled);
      }

      return true;
    },

    headingText(): string {
      if (this.isReversal) {
        return this.$tc('invoice.property.reversal', 1);
      }

      return this.$tc('invoice.model', 1);
    },

    invoice(): MInvoice {
      return MInvoice
        .query()
        .withAllRecursive()
        .find(this.invoiceID);
    },

    invoiceDatePaid(): string {
      return this.invoice?.DatePaid;
    },

    invoiceNumber(): string {
      return this.invoice?.InvoiceNumber;
    },

    isDisabled(): boolean {
      return !!((
        !utIsEmpty(this.invoice?.DatePaid) ||
        this.invoice?.InvoiceNumber === this.invoice?.CancelInvoiceNumber
      ));
    },

    isReversal(): boolean {
      if (this.invoice) {
        return this.invoice.asListData.reversal === this.$tc('common.property.yes');
      }

      return false;
    },
  },

  methods: {
    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async downloadBon(): Promise<void> {
      this.loading = true;

      let type = '';

      if (this.invoice.InvoiceNumber === this.invoice.CancelInvoiceNumber) {
        type = 'reversal';
      }

      await this.generateBon(this.invoice, type, true);

      this.loading = false;
    },

    async downloadPdf(): Promise<void> {
      this.loading = true;

      let type = '';

      if (this.invoice.InvoiceNumber === this.invoice.CancelInvoiceNumber) {
        type = 'reversal';
      }

      if (this.invoice.DeliveryInvoice) {
        await this.generateDeliveryInvoicePdf(this.invoice, type, true);
      } else {
        await this.generatePdf(this.invoice, type, true);
      }

      this.loading = false;
    },

    async downloadReversal(): Promise<void> {
      this.loading = true;

      const reversal = MInvoice
        .query()
        .withAllRecursive()
        .where('InvoiceNumber', this.invoice.CancelInvoiceNumber)
        .first();

      if (this.invoice.DeliveryInvoice) {
        await this.generateDeliveryInvoicePdf(reversal, 'reversal', true);
      } else {
        await this.generatePdf(reversal, 'reversaal', true);
      }

      this.loading = false;
    },

    showMailForm(): void {
      this.$router.push({
        name: invoiceSendMail(this.$i18n.locale).name,

        params: {
          routeToInvoice: this.routeBack.name,
        },
      });
    },

    sync(): void {
      MInvoice._update(this.invoice);
    },

    onDateChange(data): void {
      this.dirty = true;

      MInvoice.update({
        Id: this.invoice.Id,
        DatePaid: new Date(data).toISOString(),
      });
    },
  },
});
